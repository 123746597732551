dmx.Component('dropzone', {

  extends: 'form-element',

  initialData: {
    file: null,
    files: [],
    lastError: '',
  },

  attributes: {
    accept: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: 'Drop files here or click to browse.',
    },

    thumbs: {
      type: Boolean,
      default: true,
    },

    thumbsWidth: {
      type: Number,
      default: 100,
    },

    thumbsHeight: {
      type: Number,
      default: 100,
    },

    imageMaxWidth: {
      type: Number,
      default: null,
    },

    imageMaxHeight: {
      type: Number,
      default: null,
    },

    imageType: {
      type: String,
      default: null,
      enum: ['png', 'jpeg', 'webp'],
    },

    imageQuality: {
      type: Number,
      default: null,
    },
  },

  methods: {
    remove (id) {
      this._remove(id);
    },

    reset () {
      this._reset();
    },
  },

  init (node) {
    this._clickHandler = this._clickHandler.bind(this);
    this._dragoverHandler = this._dragoverHandler.bind(this);
    this._dragenterHandler = this._dragenterHandler.bind(this);
    this._dragleaveHandler = this._dragleaveHandler.bind(this);
    this._dropHandler = this._dropHandler.bind(this);
    this._changeHandler = this._changeHandler.bind(this);
    this._resetHandler = this._resetHandler.bind(this);

    this._imageTypes = {
      png: 'image/png',
      jpeg: 'image/jpeg',
      webp: 'image/webp',
      'image/png': 'image/png',
      'image/jpeg': 'image/jpeg',
      'image/webp': 'image/webp',
    };

    this._imageExtensions = {
      'image/png': 'png',
      'image/jpeg': 'jpg',
      'image/webp': 'webp',
    };

    this._form = node.form;
    this._cnt = 0;
  },

  render (node) {
    this._dropzoneElement = document.createElement('div');
    for (let attr of node.attributes) {
      //if (attr.name.startsWith('dmx-')) continue;
      if (attr.name == 'is') continue;
      this._dropzoneElement.setAttribute(attr.name, attr.value);
    }
    this.$parse(this._dropzoneElement);
    this._dropzoneElement.classList.add('dmxDropzone');

    Object.defineProperties(this._dropzoneElement, {
      willValidate: {
        get: () => true,
        set: () => {},
      },
      files: {
        get: () => {
          const files = this._form.dmxExtraData[this.$node.name];
          if (Array.isArray(files)) return files;
          return files ? [files] : [];
        },
        set: () => {},
      },
      value: {
        get: () => this.data.file ? this.data.file.name : this.data.files.map(file => file.name).join(', '),
        set: () => {},
      },
    });

    this._dropzoneElement.type = 'file';
    this._dropzoneElement.multiple = node.multiple;
    this._dropzoneElement.toggleAttribute('required', this.props.required);
    this._dropzoneElement.accept = this.props.accept;
    this._dropzoneElement.name = node.name;
    this._dropzoneElement.disabled = node.disabled;
    this._dropzoneElement.setCustomValidity = message => {
      this.set('isinvalid', message != '');
      this.set('validityMessage', message || '');
    };
    
    this._messageElement = document.createElement('div');
    this._messageElement.className = 'dmxDropzoneMessage';
    this._messageElement.innerHTML = this.props.message;
    
    this._dropzoneElement.append(this._messageElement);
    
    this._dropzoneElement.addEventListener('click', this._clickHandler);
    this._dropzoneElement.addEventListener('dragover', this._dragoverHandler);
    this._dropzoneElement.addEventListener('dragenter', this._dragenterHandler);
    this._dropzoneElement.addEventListener('dragleave', this._dragleaveHandler);
    this._dropzoneElement.addEventListener('drop', this._dropHandler);
    
    node.addEventListener('change', this._changeHandler);
    node.accept = this.props.accept;

    dmx.dom.replace(node, this._dropzoneElement);

    if (this._form) {
      if (Array.isArray(this._form.dmxExtraElements)) {
        this._form.dmxExtraElements.push(this._dropzoneElement);
      }
      this._form.addEventListener('reset', this._resetHandler);
    }
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('accept')) {
      this.$node.accept = this.props.accept;
      this._dropzoneElement.accept = this.props.accept;
    }

    if (updatedProps.has('required')) {
      this.$node.required = this.props.required;
      this._dropzoneElement.toggleAttribute('required', this.props.required);
    }

    if (updatedProps.has('message')) {
      this._updateMessage();
    }
  },

  destroy () {
    this._dropzoneElement.removeEventListener('click', this._clickHandler);
    this._dropzoneElement.removeEventListener('dragover', this._dragoverHandler);
    this._dropzoneElement.removeEventListener('dragenter', this._dragenterHandler);
    this._dropzoneElement.removeEventListener('dragleave', this._dragleaveHandler);
    this._dropzoneElement.removeEventListener('drop', this._dropHandler);

    this.$node.removeEventListener('change', this._changeHandler);

    if (this._form) {
      this._form.removeEventListener('reset', this._resetHandler);
    }

    dmx.dom.replace(this._dropzoneElement, this.$node);
  },

  _validate () {
    dmx.validate(this._dropzoneElement);

    if (this.$node.dirty) {
      this.set({
        invalid: !this.$node.validity.valid,
        validationMessage: this.$node.validationMessage,
      });
    }
  },

  _reset () {
    dmx.validateReset(this._dropzoneElement);
    this._dropzoneElement.dirty = false;
    this.$node.dirty = false;
    this.set({
      invalid: false,
      validationMessage: '',
    });
    this._remove();
    dmx.nextTick(() => this.dispatchEvent("updated"));
  },

  _updateMessage () {
    let message = this.props.message;

    if (this.data.files.length) {
      message += ` (${this.data.files.length} files)`;
    } else if (this.data.file) {
      message += ` (${this.data.file.name})`;
    }

    this._messageElement.innerHTML = message;
  },

  _addItems (items) {
    for (let i = 0; i < items.length; i++) {
      const entry = items[i].webkitGetAsEntry();

      if (entry.isFile) {
        this._addFile(items[i].getAsFile());
      } else if (entry.isDirectory) {
        this._addDirectory(entry);
      }
    }
  },

  _addDirectory (entry, path = '') {
    const reader = entry.createReader();

    reader.readEntries(entries => {
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];

        if (entry.isFile) {
          entry.file(file => {
            file.fullPath = path + file.name;
            this._addFile(file);
          });
        } else if (entry.isDirectory) {
          this._addDirectory(entry, path + entry.name + '/');
        }
      }
    });
  },

  _addFiles (files) {
    for (let i = 0; i < files.length; i++) {
      this._addFile(files[i]);
    }
  },

  _addFile (file) {
    if ((this.props.imageMaxWidth || this.props.imageMaxHeight || this.props.imageType) && !file.resized) {
      this._resizeImage(file).then(file => {
        this._addFile(file);
      });
      return;
    }

    if (this.$node.multiple) {
      this._form.dmxExtraData[this.$node.name] = this._form.dmxExtraData[this.$node.name] || [];
      this._form.dmxExtraData[this.$node.name].push(file);
    } else {
      this._remove();
      this._form.dmxExtraData[this.$node.name] = file;
    }

    file._id = ++this._cnt;

    const self = this;
    const info = {
      id: file._id,
      date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
      name: file.name,
      size: file.size,
      type: file.type,
      get dataUrl () {
        if (!file._reader && !file._dataUrl) {
          dmx.fileUtils.blobToDataURL(file).then(dataUrl => {
            file._dataUrl = dataUrl;
            if (self.$node.multiple) {
              self.set('files', self.data.files.map(f => f.id == info.id ? {...f, dataUrl} : f));
            } else {
              self.set('file', {...info, dataUrl});
            }
          }).catch(error => {
            console.error(error);
          });
        }

        return null;
      },
    };

    if (this.props.thumbs) {
      this._createThumb(file);
    }
    
    if (file.type.includes('image/') && !file._reader) {
      file._reader = new FileReader();

      file._reader.onload = () => {
        //info.dataUrl = file._reader.result;
        if (this.$node.multiple) {
          this.set('files', this.data.files.map(f => f.id == info.id ? {...f, dataUrl: file._reader.result} : f));
        } else {
          this.set('file', {...info, dataUrl: file._reader.result});
        }
      };

      file._reader.readAsDataURL(file);
    }

    if (this.$node.multiple) {
      this.set('files', [...this.data.files, info]);
    } else {
      this.set('file', info);
    }

    if (this._dropzoneElement.dirty) {
      this._validate();
    }
  },

  _remove (id) {
    if (this.$node.multiple) {
      if (!id) {
        if (Array.isArray(this._form.dmxExtraData[this.$node.name])) {
          for (let file of this._form.dmxExtraData[this.$node.name]) {
            const thumb = file._thumb;
            if (thumb) {
              thumb.remove();
              URL.revokeObjectURL(thumb._objectURL);
            }
          }
        }
        delete this._form.dmxExtraData[this.$node.name];
        this.set('files', []);
    } else {
        const index = this.data.files.findIndex(file => file.id == id);

        if (index != -1) {
          const thumb = this._form.dmxExtraData[this.$node.name][index]._thumb;
          if (thumb) {
            thumb.remove();
            URL.revokeObjectURL(thumb._objectURL);
          }
          this._form.dmxExtraData[this.$node.name].splice(index, 1);
          this.set('files', [...this.data.files.slice(0, index), ...this.data.files.slice(index + 1)]);
        }
      }
    } else if (this.data.file) {
      const thumb = this._form.dmxExtraData[this.$node.name]._thumb;
      if (thumb) {
        thumb.remove();
        URL.revokeObjectURL(thumb._objectURL);
      }
      delete this._form.dmxExtraData[this.$node.name];
      this.set('file', null);
    }

    if (this._dropzoneElement.dirty) {
      this._validate();
    }
  },

  _createThumb (file) {
    const thumb = document.createElement('div');
    thumb.id = 'dmxDropzoneThumb' + file._id;
    thumb.className = 'dmxDropzoneThumb';
    thumb.style.width = this.props.thumbsWidth + 'px';
    thumb.style.height = this.props.thumbsHeight + 'px';
    thumb.title = file.name;
    thumb._objectURL = URL.createObjectURL(file);
    thumb.style.backgroundImage = `url(${thumb._objectURL})`;
    thumb.addEventListener('click', event => {
      event.preventDefault();
      event.stopPropagation();
      this._remove(file._id);
    });

    const filename = document.createElement('div');
    filename.className = 'dmxDropzoneFilename';
    filename.textContent = file.name;
    thumb.append(filename);

    const filesize = document.createElement('div');
    filesize.className = 'dmxDropzoneFilesize';
    filesize.textContent = this._formatBytes(file.size);
    thumb.append(filesize);

    file._thumb = thumb;

    this._dropzoneElement.append(thumb);
  },

  _resizeImage (file) {
    file.resized = true;

    return new Promise(resolve => {
      if (!file.type.startsWith('image/')) {
        resolve(file);
        return;
      }

      const blobUrl = URL.createObjectURL(file);
      const img = new Image();
      img.src = blobUrl;
      img.onerror = () => URL.revokeObjectURL(blobUrl);
      img.onload = () => {
        URL.revokeObjectURL(blobUrl);

        const { imageMaxWidth, imageMaxHeight, imageType, imageQuality } = this.props;

        let width = img.width;
        let height = img.height;
        let ratio = width / height;
        let needResize = false;

        if (imageMaxWidth && width > imageMaxWidth) {
          width = imageMaxWidth;
          height = ~~(width / ratio);
          needResize = true;
        }

        if (imageMaxHeight && height > imageMaxHeight) {
          height = imageMaxHeight;
          width = ~~(height * ratio);
          needResize = true;
        }

        const newType = imageType ? this._imageTypes[imageType] : file.type;

        if (newType !== file.type || needResize) {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(blob => {
            if (blob == null) {
              return console.error('Could not resize image!');
            }
            const newName = file.name.replace(/\.\w+$/, '.' + this._imageExtensions[blob.type]);
            const newFile = new File([blob], newName, { type: blob.type });
            resolve(newFile);
          }, newType, imageQuality ? imageQuality / 100 : undefined);
        } else {
          resolve(file);
        }
      };
    });
  },

  _formatBytes (bytes) {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let i = 0;

    while (bytes >= 1000) {
      bytes /= 1000;
      i++;
    }

    return bytes.toFixed(1) + units[i];
  },

  _clickHandler (event) {
    this.$node.click();
  },

  _changeHandler (event) {
    this._addFiles(event.target.files);
    this.$node.value = '';
    this.$node.type = '';
    this.$node.type = 'file';
    if (event) this.dispatchEvent('changed');
    dmx.nextTick(() => this.dispatchEvent("updated"));
  },

  _dragoverHandler (event) {
    event.preventDefault();
    event.stopPropagation();
  },

  _dragenterHandler (event) {
    event.preventDefault();
    event.stopPropagation();

    this._dropzoneElement.classList.add('dmxDropzoneHover');
  },

  _dragleaveHandler (event) {
    this._dropzoneElement.classList.remove('dmxDropzoneHover');
  },

  _dropHandler (event) {
    event.preventDefault();
    event.stopPropagation();

    this._dropzoneElement.classList.remove('dmxDropzoneHover');

    if (!event.dataTransfer.files.length) {
      return;
    }

    if (event.dataTransfer.items.length && event.dataTransfer.items[0].webkitGetAsEntry) {
      this._addItems(event.dataTransfer.items);
    } else {
      this._addFiles(event.dataTransfer.files);
    }

    this.dispatchEvent('change');
  },

  _resetHandler (event) {
    this._reset();
    this.dispatchEvent('changed');
  },

});
